export default {
  google: {
    maps: {
      apiKey: 'AIzaSyCGXMgM2THiVdCaNk3_TdHrge11LaJb8YA'
    },
    analytics: {
      apiKey: 'G-WQ56F6VHN6'
    },
    oauth: {
      clientId: '409352233542-o8ejoeb099eh25svjro70moinje3sm1j.apps.googleusercontent.com'
    }
  },
  logrocket: {
    apiKey: 'rkfp4d/betaadvisrcom'
  },
  mapbox: {
    apiKey: 'pk.eyJ1IjoiYWR2aXNyIiwiYSI6ImNsbzRoYW1lcDAxZHgyb2xtazQxc3JyNTMifQ.pj7-xtQn_4o9Y9PJv2FB7g'
  },
  rollbar: {
    accessToken: '688eb23ca4e249708000bee2415352a3'
  },
  walkme: {
    enabled: true,
    environment: 'test'
  },
  hubspot: {
    portalId: '20711712'
  }
}